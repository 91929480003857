.ant-table-row-selected > .ant-table-cell, .ant-table-expanded-row > td{
  background-color: white !important;
}

.ant-table-expanded-row > td {
  border-bottom: 3px solid #dbdbdb !important;
}

.ant-table-expanded-row-fixed {
  padding: 0 !important;
}
