@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-dropdown, .ant-select-dropdown {
  z-index: 1350 !important;
}

.ant-select-selector {
  border-radius: 4px !important;
  border-color: #c4c4c4 !important;
  background-color: transparent !important;
}

.ant-checkbox-disabled+span {
  color: inherit !important;
}

.MuiInputBase-root {
  width: 100%;
}

.ql-tooltip  {
  left: 0 !important;
}

.ql-toolbar {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.ql-container {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ql-editor {
  min-height: 200px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@layer base {
  .default-link {
    @apply text-link-blue cursor-pointer hover:underline;
  }
}